<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="mb-4">
        <cloud-storage-status />
      </b-col>
      <b-col v-show="!isTenantStorageFull" md="12" class="mb-3">
        <b-row>
          <b-form-group class="col-md-4 text-md-right align-self-md-center">
            <label class="form-control-label">{{$t('ActionDate')}}</label>
          </b-form-group>
          <b-form-group class="col-md-6">
            <date-picker v-model="date"
                         :tag-name="'Date'"
                         :is-required="false" />
          </b-form-group>
        </b-row>

        <b-row>
          <b-form-group class="col-md-4 text-md-right align-self-md-center">
            <label class="form-control-label">{{$t('MultimediaType')}}</label>
          </b-form-group>
          <b-form-group class="col-md-6">
            <v-select transition=""
                      v-model="multimediaType"
                      :reduce="s => s.id"
                      :clearable="false"
                      :options="multimediaTypeList">
              <template #selected-option="option">
                {{$t(option.name)}}
              </template>
              <template #option="option">
                {{$t(option.name)}}
              </template>
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
        </b-row>

        <b-row>
          <b-form-group class="col-md-4 text-md-right align-self-md-center">
            <label class="form-control-label">{{$t('Description')}}</label>
          </b-form-group>
          <b-form-group class="col-md-6">
            <textarea v-model="notes"
                      name="Notes"
                      maxlength="250"
                      rows="2"
                      class="form-control" />
          </b-form-group>
        </b-row>

        <b-row>
          <div class="dropzone dropzone-default dropzone-brand col-md-12 my-3">
            <div class="alert-text">
              {{$t('FileUploadAlertInfo')}}
            </div>
            <div class="my-4">
              <vue-dropzone ref="myDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            </div>
          </div>

          <button @click="addNotesAndDate()"
                  style="float:right;"
                  type="button"
                  class="btn btn-primary btn-md btn-block">
            <i class="fa fa-cloud-upload-alt"></i> {{$t('Done')}}
          </button>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { refreshMultimediaData } from '../../../helpers/dataRefresher';
  import { getUniqueString } from '../../../utils/stringExtensions';
  import { getToday } from '../../../utils/dateTimeExtensions';
  import multimediaService from '../../../services/multimedia';
  import authService from '../../../services/auth';

  import '../../../plugins/vue-dropzone';
  import CloudStorageStatus from './CloudStorageStatus'
  import DatePicker from '../../../components/shared/DatePicker';

  export default {
    name: 'FileUpload',
    props: {
      customerId: String,
    },
    components: {
      DatePicker,
      CloudStorageStatus
    },
    data() {
      return {
        isTenantStorageFull: false,
        objectId: '',
        groupId: '',
        notes: '',
        multimediaType: 'FileAndPhotos',
        date: getToday(),
        multimediaTypeList: [],
        errorFile: []
      }
    },
    methods: {
      addNotesAndDate() {
        multimediaService.updateMultimediaDetails(this.notes, this.date, this.groupId, this.multimediaType)
          .then(response => {
            if (response == true) {
              this.$toastr.success(this.$t("Success"));
              this.$emit('closeModal');
              refreshMultimediaData();
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }
          });
      },
      getMultimediaType() {
        multimediaService.getMultimediaType()
          .then(response => {
            this.multimediaTypeList = response;
          });
      },
    },
    computed: {
      token() {
        return this.$store.getters['Auth/token'];
      },
      dropzoneOptions() {
        return {
          url: "/Multimedia/UploadFile",
          paramName: "file",
          thumbnailWidth: 150,
          maxFiles: 40,
          maxFilesize: 500,
          addRemoveLinks: true,
          dictDefaultMessage: this.$t("DropFilesHereToUpload"),
          dictRemoveFile: this.$t("Remove"),
          autoProcessQueue: true,
          parallelUploads: 1,
          headers: {
            "Authorization": this.token,
            "groupId": this.groupId.toString(),
            "objectId": this.objectId.toString(),
            "uploadedFrom": "UI"
          },
        };
      }
    },
    created: function () {
      this.objectId = this.customerId;
      this.groupId = getUniqueString();

      this.getMultimediaType();

      this.$bus.$on('tenantCloudStorageFullEvent', () => {
        this.isTenantStorageFull = true;
      });
    },
    watch: {
      token: function (newToken) {
        this.$refs.myDropzone.dropzone.options.headers.Authorization = newToken;

        for (let i = 0; i < this.errorFile.length; i++) {
          const file = this.errorFile[i];

          this.$refs.myDropzone.dropzone.processFile(file);

          var errorElementMessage = file.previewElement.querySelectorAll('.dz-error-message')[0];
          if (errorElementMessage) {
            errorElementMessage.style.display = 'none';
          }

          var errorElementMark = file.previewElement.querySelectorAll('.dz-error-mark')[0];
          if (errorElementMark) {
            errorElementMark.style.display = 'none';
          }
        }

        this.$refs.myDropzone.dropzone.options.autoProcessQueue = true;
        this.errorFile = [];
      }
    },
    mounted() {
      this.$refs.myDropzone.dropzone.on('error', (file, errorMessage, xhr) => {
        if (xhr.status === 401) {

          this.$refs.myDropzone.dropzone.options.autoProcessQueue = false;

          this.errorFile.push(file);

          authService.checkToken();
        }
      })
    }
  }
</script>
<style scoped>
  .dropzone {
    min-height: auto;
  }

    .dropzone.dropzone-default {
      padding: 20px;
      text-align: center;
      cursor: pointer;
      border: 2px dashed #dee2e6;
      border-radius: 4px;
    }

      .dropzone.dropzone-default.dropzone-brand {
        border-color: #0a7389;
      }
</style>
