<template>
    <div>
        <div class="row shadow-sm py-3">
            <div class="col-12">
                <div class="font-weight-bold d-flex align-items-center">
                    <div class="iq-alert-icon">
                      <i class="fa fa-info-circle fa-lg mr-2 text-dark"></i>
                    </div>
                    <h4 class="iq-alert-text">
                      {{$t("CloudStorageComponentHeaderText")}}
                    </h4>
                </div>
                <div class="text-dark">
                    <b>{{$t("CloudStorageComponentTotalSpaceGb")}}</b>: {{ tenantCloudStorageStatus.totalGb }} GB
                    <b>{{$t("CloudStorageComponentUsageSpaceGb")}}</b>: {{ tenantCloudStorageStatus.usageGb }} GB
                    <b>{{$t("CloudStorageComponentFreeSpaceGb")}}</b>: {{ freeGbCheck }} GB
                </div>
                <hr />
                <div v-show="tenantCloudStorageStatus.usagePercentage > 0" class="progress">

                    <div id="progressbarDiv" :class="percentageBarFullCssClass" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="percentageBarCssStyle">
                        % {{ tenantCloudStorageStatus.usagePercentage }}
                    </div>
                </div>
                <div v-if="tenantCloudStorageStatus.isStorageFull">
                    <div class="alert alert-danger mt-3">
                        <div class="iq-alert-icon">
                            <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="iq-alert-text">{{$t("CloudStorageComponentStorageFullText")}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import multimediaService from '../../../services/multimedia';

    export default {
        data() {
            return {
                tenantCloudStorageStatus: {
                    isStorageFull: false,
                    totalGb: 0,
                    freeGb: 0,
                    usageGb: 0,
                    usagePercentage: 0
                }
            };
        },
        methods: {
            getTenantCloudStorageStatus() {
                multimediaService.getTenantCloudStorageStatus().then(response => {
                    this.tenantCloudStorageStatus = response;
                    if (this.tenantCloudStorageStatus.usagePercentage > 0) {
                        document.getElementById("progressbarDiv").setAttribute("aria-valuenow", this.tenantCloudStorageStatus.usagePercentage);
                    }
                    if (this.tenantCloudStorageStatus.isStorageFull == true) {
                        this.$bus.$emit('tenantCloudStorageFullEvent');
                    }
                });
            },
        },
        computed: {
            percentageBarBackgroundColor: function () {
                var bgColor = "bg-success";
                if (this.tenantCloudStorageStatus.usagePercentage > 49)
                    bgColor = "bg-warning";
                if (this.tenantCloudStorageStatus.usagePercentage > 79)
                    bgColor = "bg-danger";

                return bgColor;
            },
            percentageBarFullCssClass: function () {
                return 'progress-bar progress-bar-striped progress-lg progress-bar-animated ' + this.percentageBarBackgroundColor;
            },
            percentageBarCssStyle: function () {
                return "width: " + this.tenantCloudStorageStatus.usagePercentage + "%";
            },
            freeGbCheck: function () {
                return this.tenantCloudStorageStatus.freeGb < 0 ? 0 : this.tenantCloudStorageStatus.freeGb;
            }
        },
        created: function () {
            this.getTenantCloudStorageStatus();
        }
    };
</script>
